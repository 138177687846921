import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["BackButton"] */ "/app/src/components/common/back-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Chips"] */ "/app/src/components/common/chips.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/collapsible.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/command.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommentsList"] */ "/app/src/components/common/comments/comments-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmDialog"] */ "/app/src/components/common/confirm-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LabelValueItem"] */ "/app/src/components/common/label-value-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeftSideNav"] */ "/app/src/components/common/left-side-nav/left-side-nav.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/sheet.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/use-toast.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleView"] */ "/app/src/components/common/toggle-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadFileArea"] */ "/app/src/components/common/upload-file-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadFileDialog"] */ "/app/src/components/common/upload-file.dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdminUsersListTable"] */ "/app/src/views/admin-users-list/table/admin-users-list-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllClientsView"] */ "/app/src/views/all-clients/all-clients-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllDocumentsView"] */ "/app/src/views/all-documents/all-documents-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllGuardiansTable"] */ "/app/src/views/all-guardians/table/all-guardians-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllServicesView"] */ "/app/src/views/all-services/all-services-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllTherapistsView"] */ "/app/src/views/all-therapists/all-therapists-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuditLogView"] */ "/app/src/views/audit-log/audit-log-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AvailableClientsView"] */ "/app/src/views/available-clients/available-clients-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatsView"] */ "/app/src/views/chats/chats-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardAdminView"] */ "/app/src/views/dashboard/dashboard-admin-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardTherapistView"] */ "/app/src/views/dashboard/dashboard-therapist-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ForgotPasswordConfirmView"] */ "/app/src/views/forgot-password-confirm/forgot-password-confirm-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ForgotPasswordForm"] */ "/app/src/views/forgot-password/forgot-pass-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginView"] */ "/app/src/views/login/login-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyClientsView"] */ "/app/src/views/my-clients/my-clients-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyDocumentsView"] */ "/app/src/views/my-documents/my-documents-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyProfileView"] */ "/app/src/views/my-profile/my-profile-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyScheduleView"] */ "/app/src/views/my-schedule/my-schedule-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyTimesheetsTable"] */ "/app/src/views/my-timesheets/table/my-timesheets-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScheduleView"] */ "/app/src/views/schedule/schedule-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServiceRolesTable"] */ "/app/src/views/service-roles/table/service-roles-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SetupNewPasswordView"] */ "/app/src/views/setup-new-password/setup-new-password-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SignaturesView"] */ "/app/src/views/signatures/signatures-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimesheetDetailsView"] */ "/app/src/views/timesheet-details/timesheet-details-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimesheetsTable"] */ "/app/src/views/timesheets/table/timesheets-table.tsx");
