import { DATE_MMDDYYYY } from '@/common/constants/regex-constants';
import { z } from 'zod';

export const updateServiceValidationSchema = z.object({
  title: z.string().min(1, { message: 'Forms.title.required' }),
  guardians: z.array(
    z.object({
      label: z.string(),
      value: z.any(),
    })
  ),
  authorizationNumber: z
    .string()
    .transform((value) => (value === '' ? undefined : Number(value)))
    .optional()
    .refine((value) => value === undefined || !isNaN(value), { message: 'Forms.invalid' })
    .refine((value) => value === undefined || value >= 0, { message: 'Forms.authorizationNumber.min' })
    .refine((value) => value === undefined || value <= 1e9, { message: 'Forms.authorizationNumber.max' }),
  authorizedTo: z
    .string()
    .min(1, { message: 'Forms.authorizedTo.required' })
    .regex(DATE_MMDDYYYY, { message: 'Forms.authorizedTo.invalid' }), // MM.DD.YYYY
  authorizedFrom: z.union([
    z.string().length(0),
    z.string().regex(DATE_MMDDYYYY, { message: 'Forms.authorizedFrom.invalid' }),
  ]),
  address: z
    .string()
    .min(1, { message: 'Forms.address.required' })
    .min(5, { message: 'Forms.address.min' })
    .max(200, { message: 'Forms.address.max' }),
  languages: z
    .array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    )
    .min(1, { message: 'Forms.languages.required' }),
  numberOfHours: z.string().min(1, { message: 'Forms.numberOfHours.required' }),
  monthly: z.number(),
  frequency: z.string().min(1, { message: 'Forms.frequency.required' }),
  therapist: z.string(),
  inPersonOnly: z.boolean().default(false).optional(),
  geofencing: z.boolean().default(false).optional(),
});

export type UpdateServiceValidationSchema = z.infer<typeof updateServiceValidationSchema>;
