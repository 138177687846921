export enum SPECIALITY_CODES {
  OT = 'OT',
  PT = 'PT',
  ST = 'ST',
  CDS = 'CDS',
  FSS = 'FSS',
  INT = 'INT',
}

export const SPECIALITY_CODES_ARRAY = ['OT', 'PT', 'ST', 'CDS', 'FSS', 'INT'];
