import { ColumnDef } from '@tanstack/react-table';
import { Chips } from '@/components/common';
import dayjs from '@/lib/dayjsConfig';
import { TIMESHEET_STATUS_COLORS } from '@/common/constants/chips-colors';
import { capitalizeFirstLetter, minutesToTime } from '@/lib/utils';
import { Timesheet } from '@/views/timesheets/interfaces/timesheet.interface';

type Params = {
  t: any;
};

export const getMyTimesheetsColumns = ({t}: Params): ColumnDef<Timesheet>[] => {
  const columns: ColumnDef<Timesheet>[] = [
    {
      id: 'Period',
      enablePinning: false,
      header: () => <span>{t('Tables.period')}</span>,
      cell: ({ row }) => {
        if (row.original.periodTo && row.original.periodFrom) {
          return <span>{`${dayjs(row.original.periodFrom).format('MMM')} ${dayjs(row.original.periodFrom).format('DD')} - ${dayjs(row.original.periodTo).format('MMM')} ${dayjs(row.original.periodTo).format('DD')}`}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of Shifts',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfShifts')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.shiftsCount}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of Hours Worked',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfHoursWorked')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.totalDuration ? minutesToTime(row.original.report.totalDuration) : '---'}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    }
  ];

  return columns;
};
