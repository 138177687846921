import { PermissionsTypes } from '../enums/permissions-types';
import { RoleTypes } from '../enums/role-types';

export const ALLOWED_PERMISSIONS: Record<RoleTypes, PermissionsTypes[]> = {
  'Super admin': [
    PermissionsTypes.VIEW_ADMIN_USER_LIST,
    PermissionsTypes.CREATE_NEW_ADMIN,
    PermissionsTypes.VIEW_ADMIN_PROFILE,
    PermissionsTypes.CHANGE_ADMIN_PROFILE,
    PermissionsTypes.SOFT_DELETE_ADMIN_USER,
    PermissionsTypes.CREATE_THERAPIST,
    PermissionsTypes.SET_PAY_RATE,
    PermissionsTypes.VIEW_ALL_THERAPISTS,
    PermissionsTypes.VIEW_THERAPIST_DETAILS,
    PermissionsTypes.MANAGE_THERAPIST_DETAILS,
    PermissionsTypes.SOFT_DELETE_THERAPIST_PROFILE,
    PermissionsTypes.VIEW_LIST_OF_SERVICE_ROLES,
    PermissionsTypes.CREATE_SERVICE_ROLE,
    PermissionsTypes.SOFT_DELETE_SERVICE_ROLE,
    PermissionsTypes.CREATE_CLIENT,
    PermissionsTypes.CREATE_GUARDIAN,
    PermissionsTypes.VIEW_ALL_CLIENTS,
    PermissionsTypes.EDIT_CLIENT,
    PermissionsTypes.DELETE_CLIENT,
    PermissionsTypes.VIEW_GUARDIAN_DETAILS,
    PermissionsTypes.EDIT_GUARDIAN,
    PermissionsTypes.DELETE_GUARDIAN,
    PermissionsTypes.CREATE_SERVICE,
    PermissionsTypes.VIEW_ALL_SERVICES,
    PermissionsTypes.VIEW_SERVICE_DETAILS,
    PermissionsTypes.EDIT_SERVICE,
    PermissionsTypes.SOFT_DELETE_SERVICE,
    PermissionsTypes.VIEW_ALL_DOCUMENTS,
    PermissionsTypes.VIEW_DOCUMENT_IN_THERAPIST,
    PermissionsTypes.MANAGE_DOCUMENT_DETAILS,
    PermissionsTypes.CHANGE_REPORT_FILE,
    PermissionsTypes.CHANGE_REPORT_FILE_NOTES,
    PermissionsTypes.APPROVE_OR_REJECT_REPORT,
    PermissionsTypes.MANUAL_CREATION_OF_DOCUMENT,
    PermissionsTypes.SOFT_DELETE_DOCUMENT,
    PermissionsTypes.VIEW_FULL_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
    PermissionsTypes.ADD_SHIFTS_LIKE_ADMINS,
    PermissionsTypes.VIEW_SIGNATURES,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
    PermissionsTypes.SOFT_DELETE_SHIFT,
    PermissionsTypes.SOFT_DELETE_SHIFT_NO_SHOW,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.CANCEL_COMPLETED_SHIFT,
    PermissionsTypes.VIEW_CANCEL_SHIFTS,
    PermissionsTypes.DEPARTMENTS_MANAGE,
    PermissionsTypes.CREATE_CHAT_GROUP,
    PermissionsTypes.EDIT_CHAT_GROUP,
    PermissionsTypes.DELETE_CHAT_GROUP,
    PermissionsTypes.VIEW_ACTIVITY_LOGS,
    PermissionsTypes.VIEW_ACTIVITY_LOG_DETAILS,
    PermissionsTypes.VIEW_TIMESHEETS_LIST,
    PermissionsTypes.VIEW_TIMESHEET_DETAILS,
    PermissionsTypes.AUTO_CREATION_OF_TIMESHEET,
    PermissionsTypes.REFRESH_CALCULATION,
    PermissionsTypes.APPROVE_TIMESHEET,
    PermissionsTypes.AUTHORIZATION_OF_THE_SERVICE,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.MANAGE_ON_HOLD_SERVICES,
    PermissionsTypes.ADMIN_DASHBOARD,
    PermissionsTypes.VIEW_ALL_GUARDIANS,
    PermissionsTypes.VIEW_FILTER_ARCHIVED,
    PermissionsTypes.RESEND_INVITATION_LINK,
    PermissionsTypes.PREVIEW_THERAPIST_PROFILE,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.VIEW_ADP_ID,
    PermissionsTypes.TRIGGER_SMS_FOR_SIGN_SHIFT,
    PermissionsTypes.EDIT_DOCUMENT,
    PermissionsTypes.VIEW_REPORTS,
  ],
  Admin: [
    PermissionsTypes.VIEW_ADMIN_USER_LIST,
    PermissionsTypes.VIEW_ADMIN_PROFILE,
    PermissionsTypes.CREATE_THERAPIST,
    PermissionsTypes.VIEW_ALL_THERAPISTS,
    PermissionsTypes.VIEW_THERAPIST_DETAILS,
    PermissionsTypes.MANAGE_THERAPIST_DETAILS,
    PermissionsTypes.SOFT_DELETE_THERAPIST_PROFILE,
    PermissionsTypes.VIEW_LIST_OF_SERVICE_ROLES,
    PermissionsTypes.CREATE_SERVICE_ROLE,
    PermissionsTypes.SOFT_DELETE_SERVICE_ROLE,
    PermissionsTypes.CREATE_CLIENT,
    PermissionsTypes.CREATE_GUARDIAN,
    PermissionsTypes.VIEW_ALL_CLIENTS,
    PermissionsTypes.EDIT_CLIENT,
    PermissionsTypes.DELETE_CLIENT,
    PermissionsTypes.VIEW_GUARDIAN_DETAILS,
    PermissionsTypes.EDIT_GUARDIAN,
    PermissionsTypes.DELETE_GUARDIAN,
    PermissionsTypes.CREATE_SERVICE,
    PermissionsTypes.VIEW_ALL_SERVICES,
    PermissionsTypes.VIEW_SERVICE_DETAILS,
    PermissionsTypes.EDIT_SERVICE,
    PermissionsTypes.SOFT_DELETE_SERVICE,
    PermissionsTypes.VIEW_ALL_DOCUMENTS,
    PermissionsTypes.VIEW_DOCUMENT_IN_THERAPIST,
    PermissionsTypes.MANAGE_DOCUMENT_DETAILS,
    PermissionsTypes.CHANGE_REPORT_FILE,
    PermissionsTypes.CHANGE_REPORT_FILE_NOTES,
    PermissionsTypes.APPROVE_OR_REJECT_REPORT,
    PermissionsTypes.MANUAL_CREATION_OF_DOCUMENT,
    PermissionsTypes.SOFT_DELETE_DOCUMENT,
    PermissionsTypes.VIEW_FULL_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
    PermissionsTypes.ADD_SHIFTS_LIKE_ADMINS,
    PermissionsTypes.VIEW_SIGNATURES,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
    PermissionsTypes.SOFT_DELETE_SHIFT,
    PermissionsTypes.SOFT_DELETE_SHIFT_NO_SHOW,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.CANCEL_COMPLETED_SHIFT,
    PermissionsTypes.VIEW_CANCEL_SHIFTS,
    PermissionsTypes.DEPARTMENTS_MANAGE,
    PermissionsTypes.CREATE_CHAT_GROUP,
    PermissionsTypes.EDIT_CHAT_GROUP,
    PermissionsTypes.DELETE_CHAT_GROUP,
    PermissionsTypes.VIEW_ACTIVITY_LOGS,
    PermissionsTypes.VIEW_ACTIVITY_LOG_DETAILS,
    PermissionsTypes.AUTHORIZATION_OF_THE_SERVICE,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.MANAGE_ON_HOLD_SERVICES,
    PermissionsTypes.ADMIN_DASHBOARD,
    PermissionsTypes.VIEW_ALL_GUARDIANS,
    PermissionsTypes.VIEW_FILTER_ARCHIVED,
    PermissionsTypes.RESEND_INVITATION_LINK,
    PermissionsTypes.PREVIEW_THERAPIST_PROFILE,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.VIEW_ADP_ID,
    PermissionsTypes.TRIGGER_SMS_FOR_SIGN_SHIFT,
    PermissionsTypes.EDIT_DOCUMENT,
    PermissionsTypes.VIEW_REPORTS,
  ],
  Coordinator: [
    PermissionsTypes.VIEW_ALL_THERAPISTS,
    PermissionsTypes.VIEW_THERAPIST_DETAILS,
    PermissionsTypes.VIEW_LIST_OF_SERVICE_ROLES,
    PermissionsTypes.VIEW_ALL_CLIENTS,
    PermissionsTypes.VIEW_GUARDIAN_DETAILS,
    PermissionsTypes.VIEW_ALL_SERVICES,
    PermissionsTypes.VIEW_CANCEL_SHIFTS,
    PermissionsTypes.VIEW_SERVICE_DETAILS,
    PermissionsTypes.VIEW_ALL_DOCUMENTS,
    PermissionsTypes.VIEW_DOCUMENT_IN_THERAPIST,
    PermissionsTypes.VIEW_FULL_SCHEDULE,
    PermissionsTypes.VIEW_SIGNATURES,
    PermissionsTypes.AUTHORIZATION_OF_THE_SERVICE,
    PermissionsTypes.ADMIN_DASHBOARD,
    PermissionsTypes.VIEW_ALL_GUARDIANS,
    PermissionsTypes.VIEW_FILTER_ARCHIVED,
    PermissionsTypes.PREVIEW_THERAPIST_PROFILE,
  ],
  Assistant: [
    PermissionsTypes.VIEW_MY_DOCUMENTS,
    PermissionsTypes.CHANGE_REPORT_FILE_NOTES,
    PermissionsTypes.MY_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.AVAILABLE_CLIENTS_DETAILS,
    PermissionsTypes.AVAILABLE_CLIENTS_LIST,
    PermissionsTypes.MY_CLIENTS_LIST,
    PermissionsTypes.VIEW_MY_CLIENT_DETAILS,
    PermissionsTypes.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.THERAPIST_DASHBOARD,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.PRESUBMIT_DOCUMENT,
    PermissionsTypes.CHANGE_SHIFT_NOTES,
    PermissionsTypes.VIEW_MY_TIMESHEETS_LIST,
  ],
  'Assistant Limited': [
    PermissionsTypes.VIEW_MY_DOCUMENTS,
    PermissionsTypes.CHANGE_REPORT_FILE_NOTES,
    PermissionsTypes.MY_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.MY_CLIENTS_LIST,
    PermissionsTypes.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PermissionsTypes.VIEW_MY_CLIENT_DETAILS,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.THERAPIST_DASHBOARD,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.PRESUBMIT_DOCUMENT,
    PermissionsTypes.CHANGE_SHIFT_NOTES,
    PermissionsTypes.VIEW_MY_TIMESHEETS_LIST,
  ],
  Therapist: [
    PermissionsTypes.VIEW_THERAPIST_ASSISTANTS,
    PermissionsTypes.VIEW_MY_DOCUMENTS,
    PermissionsTypes.VIEW_DOCUMENT_IN_THERAPIST,
    PermissionsTypes.SUBMIT_DOCUMENT,
    PermissionsTypes.MY_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.AVAILABLE_CLIENTS_LIST,
    PermissionsTypes.AVAILABLE_CLIENTS_DETAILS,
    PermissionsTypes.MY_CLIENTS_LIST,
    PermissionsTypes.VIEW_MY_CLIENT_DETAILS,
    PermissionsTypes.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.VIEW_MY_SHIFTS,
    PermissionsTypes.THERAPIST_DASHBOARD,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.CHANGE_SHIFT_NOTES,
    PermissionsTypes.VIEW_MY_TIMESHEETS_LIST,
  ],
  'Therapist Limited': [
    PermissionsTypes.VIEW_THERAPIST_ASSISTANTS,
    PermissionsTypes.VIEW_MY_DOCUMENTS,
    PermissionsTypes.VIEW_DOCUMENT_IN_THERAPIST,
    PermissionsTypes.SUBMIT_DOCUMENT,
    PermissionsTypes.MY_SCHEDULE,
    PermissionsTypes.ADD_SHIFTS,
    PermissionsTypes.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PermissionsTypes.CHANGE_SHIFT_DETAILS,
    PermissionsTypes.CANCEL_SHIFT,
    PermissionsTypes.MY_CLIENTS_LIST,
    PermissionsTypes.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PermissionsTypes.VIEW_MY_CLIENT_DETAILS,
    PermissionsTypes.VIEW_MY_SHIFTS,
    PermissionsTypes.RETURN_CLIENT,
    PermissionsTypes.THERAPIST_DASHBOARD,
    PermissionsTypes.DECLINE_SERVICE,
    PermissionsTypes.CHANGE_SHIFT_NOTES,
    PermissionsTypes.VIEW_MY_TIMESHEETS_LIST,
  ],
  Guardian: []
};
