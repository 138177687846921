'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { REGIONAL_CENTERS } from '@/common/constants';
import { DOCUMENT_STATUS_TYPES, DOCUMENT_STATUSES, DOCUMENT_TYPES } from '@/common/constants/common';

export interface FilterMyDocumentsParams {
  regionalCenter?: string;
  search?: string;
  status?: string;
  reportType?: string;
}

interface Props {
  value?: FilterMyDocumentsParams;
  onApply: (value: FilterMyDocumentsParams) => void;
  onSaveAsDefault: (value: FilterMyDocumentsParams) => void;
  onCancel: () => void;
}

export function MyDocumentsFilterForm({ value, onApply, onSaveAsDefault, onCancel }: Props) {
  const t = useTranslations();

  const form = useForm<FilterMyDocumentsParams>({
    defaultValues: {
      regionalCenter: value ? value.regionalCenter : '',
      status: value ? value.status : '',
      reportType: value ? value.reportType : '',
    },
  });

  const onSubmit: SubmitHandler<FilterMyDocumentsParams> = (data: FilterMyDocumentsParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="status"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.status.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.status.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {/* @TODO: Change Past due to Expired on BE */}
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Completed}>Completed</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Pending}>Pending</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Prepared}>Prepared</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Submitted}>Submitted</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Expired}>Expired</SelectItem>
                        {/* {DOCUMENT_STATUSES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))} */}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>


          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="reportType"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.reportType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.reportType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {DOCUMENT_TYPES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="sm" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              onSaveAsDefault(form.getValues());
            }}
            type="button"
            variant="outline"
            size="sm"
            className="mt-8"
          >
            {t('Buttons.save')}
          </Button>
          <Button type="submit" size="sm" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
