'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  GooglePlacesInput,
  TagsSelector,
  Checkbox,
  FormDescription,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, transformApiDateToViewDate, transformDateToViewDate } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { useMask } from '@react-input/mask';
import { Guardian, Service } from '../interfaces/services.interface';
import { DialogFooter } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { DATE_MASK, FREQUENCY, Frequency, HOURS_FREQUENCY, LANGUAGES_TAGS } from '@/common/constants';
import { updateServiceValidationSchema, UpdateServiceValidationSchema } from './update-service-validation-schema';
import { useUpdateServiceMutation } from '@/hooks/fetchers/mutations/services/useUpdateServiceMutation';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';

const LANGUAGES = LANGUAGES_TAGS();

interface Props {
  service: Service;
  onDelete?: (value: UpdateServiceValidationSchema) => void;
  onCancel?: () => void;
  onRefresh: () => void;
}

export function UpdateServiceForm({ service, onDelete, onCancel, onRefresh }: Props) {
  const t = useTranslations();
  const [keywordTherapist, setKeywordTherapist] = useState('');
  const inputAuthorizedToRef = useMask({ ...DATE_MASK });
  const inputAuthorizedFromRef = useMask({ ...DATE_MASK });

  const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: therapist,
        })),
    }
  );

  const { mutate: update, isPending: isUpdating } = useUpdateServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.serviceUpdated'),
      });
      onRefresh();
    },
  });

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  };

  const form = useForm<UpdateServiceValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(updateServiceValidationSchema),
    defaultValues: {
      authorizationNumber: service.authorizationNumber ?? undefined,
      title: service.title ?? '',
      guardians: service.guardians
        ? service.guardians.map((guardian: Guardian) => ({
            label: `${guardian.firstName} ${guardian.lastName}`,
            value: guardian,
          }))
        : [],
      authorizedTo: transformDateToViewDate(service.authorizedTo, false),
      authorizedFrom: transformApiDateToViewDate(service.authorizedFrom),
      address: service.address ?? '',
      languages: service.languages
        ? service.languages.map((lang: string) => ({ label: lang, value: { id: lang } }))
        : [],
      monthly: service.monthly ? service.monthly : 0,
      numberOfHours: service.hours ? service.hours.toString() : '',
      frequency: service.frequency ? service.frequency : '',
      therapist: service.therapist ? JSON.stringify(service.therapist) : '',
      inPersonOnly: service.inPersonOnly,
      geofencing: service.geofencingOn,
    },
  });

  const frequencySelector = form.watch('frequency');
  const numberOfHoursSelector = form.watch('numberOfHours');

  useEffect(() => {
    const { frequency, numberOfHours } = form.getValues();
    if (frequency && numberOfHours) {
      const numberOfHoursValues = parseInt(numberOfHours, 10);
      form.setValue('monthly', frequency === 'week' ? numberOfHoursValues * 5 : numberOfHoursValues);
    }
  }, [frequencySelector, numberOfHoursSelector]);

  const handleDelete = () => {
    if (onDelete) {
      onDelete(form.getValues());
    }
  };

  const onSubmit: SubmitHandler<UpdateServiceValidationSchema> = async (value: UpdateServiceValidationSchema) => {
    update({
      id: service.id,
      value,
    });
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
            <div className="relative">
              <FormField
                control={form.control}
                name="title"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.title.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.title.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="guardians"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.guardians.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        disabled
                        values={(field.value ? field.value : []) as TagItem[]}
                        onChoose={field.onChange}
                        placeholder={t('Forms.guardians.label')}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="authorizedFrom"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.authorizedFrom.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputAuthorizedFromRef}
                        type="text"
                        placeholder={t('Forms.authorizedFrom.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="authorizedTo"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.authorizedTo.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputAuthorizedToRef}
                        type="text"
                        placeholder={t('Forms.authorizedTo.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="address"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.address.label')}</FormLabel>
                    <FormControl>
                      <GooglePlacesInput
                        values={field.value}
                        onChanges={field.onChange}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.therapist.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordTherapist('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByTherapists}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          ) : null}

                          {therapists
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <div className="flex flex-row gap-1">
                <FormField
                  control={form.control}
                  name="numberOfHours"
                  render={({ field, fieldState }) => (
                    <FormItem className="w-full">
                      <FormLabel isRequired>{t('Forms.numberOfHours.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.numberOfHours.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {HOURS_FREQUENCY.map((item) => (
                              <SelectItem key={item} value={item.toString()}>
                                {item}h
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="frequency"
                  render={({ field, fieldState }) => (
                    <FormItem className="w-full">
                      <FormLabel isRequired>{t('Forms.frequency.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.frequency.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {FREQUENCY.map((item) => (
                              <SelectItem key={item} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="authorizationNumber"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.authorizationNumber.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="number"
                        placeholder={t('Forms.authorizationNumber.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="monthly"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.monthly.label')}</FormLabel>
                    <FormControl>
                      <Input
                        disabled
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.monthly.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="languages"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.languages.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        values={(field.value ? field.value : []) as TagItem[]}
                        data={LANGUAGES}
                        onChoose={field.onChange}
                        placeholder={t('Forms.languages.label')}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="inPersonOnly"
              render={({ field }) => (
                <FormItem className="my-4 flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="cursor-pointer">{t('Forms.inPersonOnly.label')}</FormLabel>
                    <FormDescription>{t('Forms.inPersonOnly.description')}</FormDescription>
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="geofencing"
              render={({ field }) => (
                <FormItem className="my-4 flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="cursor-pointer">{t('Forms.geofencing.label')}</FormLabel>
                    <FormDescription>{t('Forms.geofencing.description')}</FormDescription>
                  </div>
                </FormItem>
              )}
            />
          </div>
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={handleDelete} variant="outlineDestructive" type="button" size="lg" className="mt-5">
            {t('Buttons.deleteService')}
          </Button>
          <Button type="submit" size="lg" className="mt-5" disabled={isUpdating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
