export enum TypesOfShiftRepeat {
  SINGLE = 'Single',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum TableTypes {
  ALL_SHIFTS = 'all-shifts',
  MY_CLIENTS = 'my-clients',
  SIGNATURES = 'signatures',
  TIMESHEETS = 'timesheets',
  MY_TIMESHEETS = 'my-timesheets',
  COLLAPSIBLE = 'collapsible',
  ALL_CLIENTS = 'all-clients',
  ALL_SERVICES = 'all-services',
  MY_DOCUMENTS = 'my-documents',
  ALL_DOCUMENTS = 'all-documents',
  ALL_GUARDIANS = 'all-guardians',
  SERVICE_ROLES = 'service-roles',
  ALL_THERAPISTS = 'all-therapists',
  BILLING_REPORTS = 'billing-report',
  ADMIN_USERS_LIST = 'admin-users-list',
  FREQUENCY_REPORT = 'frequency-report',
  AVAILABLE_CLIENTS = 'available-clients',
  TIMESHEET_DETAILS = 'timesheet-details',
  DETAILED_CALCULATION = 'detailed-calculation',
  MY_DETAILED_CALCULATION = 'my-detailed-calculation',
  INITIAL_VISITS_REPORTS = 'initial-visits-report',
  UNSIGNED_SHIFTS_REPORT = 'unsigned-shifts-report',
  PERFORMANCE_REPORTS = 'performance-report',
}

export enum UserRoleChangeReason {
  // RESTORED_BY_ADMIN = 'restored-by-admin',
  // UPDATED_BY_ADMIN = 'updated-by-admin',
  // RESTORED_AFTER_TIME_LIMIT = 'restored-after-time-limit',
  // RESTORED_AFTER_REPORTS_FIXED = 'restored-after-reports-fixed',
  START_OF_PROBATION = 'start-of-probation-period',
  LIMITED_BY_ADMIN = 'limited-by-admin',
  LOW_PARTICIPATION = 'low-participation',
  POOR_SERVICE_QUALITY = 'poor-service-quality',
  PAST_DUE_REPORTS = 'past-due-reports',
}
